<template>
  <div class="relative">
    <content-loader
      v-if="isLoading && dimensionsLoaded"
      data-cy="content-loader"
      :height="height"
      :width="width"
      class="absolute z-40 mx-1"
    />
    <div
      ref="slotedItem"
      :class="{ 'opacity-0': isLoading }"
      data-cy="content-loader-content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ContentLoader } from "vue-content-loader";
import { computed, ref } from "vue";

defineProps({
  isLoading: {
    type: Boolean,
    required: true
  }
});

const slotedItem = ref<HTMLElement | null>(null);
const height = computed(() => slotedItem.value?.offsetHeight);
const width = computed(() => slotedItem.value?.offsetWidth);
const dimensionsLoaded = computed(() => !!width.value && !!height.value);
</script>
